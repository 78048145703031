export type Credential = globalThis.Credential;

export type PublicKeyCredential = globalThis.PublicKeyCredential;

export type PublicKeyCredentialCreationOptions = globalThis.PublicKeyCredentialCreationOptions;

export type AuthenticatorResponse = globalThis.AuthenticatorResponse;

export type PublicKeyCredentialRequestOptions = globalThis.PublicKeyCredentialRequestOptions;

export interface PublicKeyOptions {
  data: {
    publicKeyOptions: PublicKeyCredentialCreationOptions
  }
}

/**
 * Decodes a BASE64 URL string into a normal string.
 */
function base64UrlDecode(input: string): string|Iterable<never> {
  let output = input.replace(/-/g, '+').replace(/_/g, '/');
  const pad = input.length % 4;

  if (pad) {
    if (pad === 1) {
      throw new Error(
        'InvalidLengthError: Input base64url string is the wrong length to determine padding',
      );
    }

    output += new Array(5 - pad).join('=');
  }

  return window.atob(output);
}

/**
 * Transform a string into Uint8Array instance.
 */
function uint8Array(input: string, atob = false): Uint8Array {
  const arrayLike = atob ? window.atob(input) : base64UrlDecode(input);
  const map = (c: string) => c.charCodeAt(0);

  return Uint8Array.from(
    Array.from(arrayLike),
    map,
  );
}

/**
 * Encodes an array of bytes to a BASE64 URL string
 */
function arrayToBase64String(arrayBuffer: ArrayBuffer|Uint8Array): string {
  return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
}

/**
   * Parses the Public Key Options received from the Server for the browser.
   *
   * @param publicKey { PublicKeyCredentialCreationOptions }
   * @returns { Object }
   */
/* eslint-disable no-param-reassign */
function parseIncomingServerOptions(publicKey: PublicKeyCredentialCreationOptions): PublicKeyCredentialCreationOptions {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  publicKey.challenge = uint8Array(publicKey.challenge);

  if (publicKey.user !== undefined) {
    publicKey.user = {
      ...publicKey.user,
      id: uint8Array(publicKey.user.id.toString(), true),
    };
  }

  [
    'excludeCredentials',
    'allowCredentials',
  ]
    .filter((key: string) => publicKey[key as keyof PublicKeyCredentialCreationOptions] !== undefined)
    .forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      publicKey[key] = publicKey[key].map((data: PublicKeyCredentialDescriptor) => ({
        ...data,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: uint8Array(data.id),
      }));
    });

  return publicKey;
}
/* eslint-enable no-param-reassign */

/**
   * Parses the outgoing credentials from the browser to the server.
   *
   * @param credentials {Credential|PublicKeyCredential}
   * @return {{response: {string}, rawId: string, id: string, type: string}}
   */
function parseOutgoingCredentials(credentials: Credential|PublicKeyCredential): PublicKeyCredential {
  const parseCredentials: PublicKeyCredential = {
    id: credentials.id,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    type: credentials.type,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    rawId: arrayToBase64String(credentials.rawId),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    response: {},
  };

  [
    'clientDataJSON',
    'attestationObject',
    'authenticatorData',
    'signature',
    'userHandle',
  ]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .filter((key: string) => credentials.response[key as keyof AuthenticatorResponse] !== undefined)
    .forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parseCredentials.response[key as keyof AuthenticatorResponse] = arrayToBase64String(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        credentials.response[key as keyof AuthenticatorResponse],
      );
    });
  return parseCredentials;
}

export { parseIncomingServerOptions, parseOutgoingCredentials };
