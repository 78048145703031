
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import {
  parseIncomingServerOptions,
  parseOutgoingCredentials,
  PublicKeyCredentialCreationOptions,
  PublicKeyCredentialRequestOptions,
} from '@/plugins/webAuthn';
import VJsfForm from '@/components/commonComponents/VJsfForm.vue';
import useAuthStore from '@/stores/auth';
import { FetchPolicy } from 'apollo-client';
import {
  Model,
  Schema,
  Options,
  Valid,
} from '@/types/vjsf';
import Message from '@/components/mixins/Message.vue';

interface Tokens {
  token: string,
  refreshToken: string,
  expiresIn: number,
}

@Component({
  name: 'WebAuthnLogin',
  components: {
    VJsfForm,
  },
  data: () => ({
    model: { } as Model,
    schema: { type: 'object', properties: {} } as Schema,
    options: {} as Options,
    valid: null as Valid,
    metadata: undefined,
  }),
})
export default class WebAuthnLogin extends Vue {
  private authStore = useAuthStore();

  private loading = false;

  private assertionKey: string | undefined;

  protected mounted(): void {
    this.fetchData();
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first'): void {
    this.loading = true;

    import('@/graphql/queries/web-authn-login-form')
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
      }))
      .then((response) => {
        this.$data.model = response.data.loginForm.model;
        this.$data.valid = response.data.loginForm.valid;
        this.$data.options = response.data.loginForm.options;
        this.$data.schema = response.data.loginForm.schema;
        this.$data.metadata = response.data.loginForm.metadata;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Click event: try to log into the system. If everything is correct the user will be routed to
   * the dashboard. In all other cases an error will be shown.
   */
  private login(): void {
    this.loading = true;

    this.getPublicKeyOptions()
      .then((options: PublicKeyCredentialCreationOptions) => WebAuthnLogin.getAssertion(options))
      .then((credential: Credential) => this.sendCredential(credential))
      .then((tokens) => this.storeToken(tokens))
      .then(() => {
        const { redirect } = this.$route.query;
        this.navigateTo((redirect ?? 'dashboard') as string);
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => { this.loading = false; });
  }

  /**
   * Get the public key options object from the backend. This object is needed to create the QR-code
   * later on in the process
   */
  private getPublicKeyOptions(): Promise<PublicKeyCredentialCreationOptions> {
    const variables = {
      ...this.$data.model,
    };

    return new Promise((resolve, reject) => {
      import('@/graphql/queries/web-authn-assertion')
        .then(({ default: query }) => this.$apollo.query({
          fetchPolicy: 'network-only',
          query,
          variables,
        }))
        .then((response) => {
          this.assertionKey = response.data.publicKeyCredentialRequestOptions.key;
          resolve(parseIncomingServerOptions(response.data.publicKeyCredentialRequestOptions.assertion));
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * This function shows the QR-code on the screen
   */
  private static getAssertion(options: PublicKeyCredentialRequestOptions): Promise<Credential> {
    return new Promise((resolve, reject) => {
      navigator.credentials.get({
        publicKey: options,
      })
        .then((assertion: Credential | null) => {
          if (assertion) {
            resolve(assertion);
          } else {
            reject(new Error('No Assertion can be made'));
          }
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Send the credentials to the backend. If everything is alright, a valid token will be returned
   */
  private sendCredential(credentials: Credential): Promise<Tokens> {
    const variables = {
      data: parseOutgoingCredentials(credentials),
      ...this.$data.model,
      key: this.assertionKey,
    };

    return new Promise((resolve, reject) => {
      import('@/graphql/mutations/web-authn-login')
        .then(({ default: mutation }) => this.$apollo.mutate({ mutation, variables }))
        .then((response) => resolve(response.data.webauthnLogin))
        .catch((error) => reject(error));
    });
  }

  /**
   * navigates to a specific page. At this moment the destinations are locked to a set of locations
   */
  private navigateTo(destination: string): void {
    const isNamedRoute = ['dashboard', 'login', 'register'].includes(destination);

    if (isNamedRoute) {
      this.$router.push({ name: destination });
    } else {
      this.$router.push(destination);
    }
  }

  private storeToken(tokens: Tokens): void {
    this.authStore.storeTokens(tokens.token, tokens.refreshToken, tokens.expiresIn);
  }

  private static removeSubmitButtonFromSchema(schema: Schema): { schema: Schema, submitButton: string } {
    const filteredSchema: Schema = schema;
    let submitButton = '';

    // Loop through all the properties
    Object.keys(filteredSchema.properties).forEach((key) => {
      const property = filteredSchema.properties[key];

      if (property['x-props']?.type === 'submit') {
        submitButton = filteredSchema.properties[key]['x-props']?.value ?? '';
        delete filteredSchema.properties[key];
      }
    });

    return { schema: filteredSchema, submitButton };
  }
}
