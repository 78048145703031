
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import {
  parseOutgoingCredentials,
  parseIncomingServerOptions,
  Credential,
  PublicKeyCredentialCreationOptions,
  PublicKeyCredential,
  PublicKeyOptions,
} from '@/plugins/webAuthn';
import { FetchPolicy } from 'apollo-client';
import {
  Model,
  Options,
  Schema,
  Valid,
} from '@/types/vjsf';
import VJsfForm from '@/components/commonComponents/VJsfForm.vue';
import Message from '@/components/mixins/Message.vue';

@Component({
  name: 'webAuthnRegistration',
  components: {
    VJsfForm,
  },
  data: () => ({
    model: { } as Model,
    schema: { type: 'object', properties: {} } as Schema,
    options: {} as Options,
    valid: null as Valid,
    email: undefined,
    token: undefined,
    metadata: undefined,
  }),
})
export default class webAuthnRegistration extends Vue {
  private loading = false;

  private disabledSubmit = false;

  private publicKeyOptions: PublicKeyCredentialCreationOptions | undefined;

  protected async mounted(): Promise<void> {
    this.$data.email = (this.$route.query.email).toString();
    this.$data.token = (this.$route.query.token).toString();

    if (this.$data.email !== undefined && this.$data.token !== undefined) {
      this.fetchData('network-only');
    }
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first'): void {
    this.loading = true;

    import('@/graphql/queries/web-authn-register-form')
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
      }))
      .then((response) => {
        this.$data.model = response.data.form.model;
        this.$data.valid = response.data.form.valid;
        this.$data.options = response.data.form.options;
        this.$data.schema = response.data.form.schema;
        this.$data.metadata = response.data.form.metadata;
      })
      .then(() => this.getPublicKeyOptions())
      .then((publicKeyOptions) => {
        this.publicKeyOptions = publicKeyOptions;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        this.disabledSubmit = true;
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * Click event: Register the user and handles the errors in the catch
   */
  private register(): void {
    this.loading = true;
    this.createCredentials()
      .then((credentials) => this.registerUser(credentials))
      .then(() => this.navigateTo('login'))
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * WebAuthn needs an publicKeyOptions object. This object is defined in the backend and must be
   * fetched by the frontend via the API. If everything goes as expected, the publicKey object will be
   * returned. In all other cases an error will be given and pushed up the parent who called this
   * function.
   */
  private getPublicKeyOptions(): Promise<PublicKeyCredentialCreationOptions> {
    const variables = {
      email: this.$data.email,
      token: this.$data.token,
    };

    return new Promise((resolve, reject) => {
      import('@/graphql/queries/web-authn-recover')
        .then(({ default: query }) => this.$apollo.query({
          query,
          variables,
        }))
        .then((response: PublicKeyOptions) => resolve(parseIncomingServerOptions(response.data.publicKeyOptions)))
        .catch((error) => reject(error));
    });
  }

  /**
   * Creates the credentials needed for WebAuthn. This function shows the QR-code on the screen.
   * If something goes wrongs somewhere in this process, the promise is rejected with an error.
   */
  private async createCredentials(): Promise<Credential> {
    return new Promise((resolve, reject) => {
      navigator.credentials.create({
        publicKey: this.publicKeyOptions,
      })
        .then((credentials: Credential | null) => (credentials
          ? resolve(parseOutgoingCredentials(credentials))
          : reject(new Error('No credentials made'))))
        .catch((error) => reject(error));
    });
  }

  /**
   * Registers the user in the backend with the needed information. Resolves itself when done and
   * rejects when an error occurs
   */
  private async registerUser(data: Credential | PublicKeyCredential): Promise<void> {
    return new Promise((resolve, reject) => {
      import('@/graphql/mutations/web-authn-register')
        .then(({ default: mutation }) => this.$apollo.mutate({
          mutation,
          variables: {
            data,
            token: this.$data.token,
            email: this.$data.email,
          },
        }))
        .then((response) => (response.data.token !== null
          ? resolve()
          : reject(new Error('Registration error'))))
        .catch((error) => reject(error));
    });
  }

  /**
   * navigates to a specific page. At this moment the destinations are locked to a set of locations
   */
  private navigateTo(destination: 'dashboard' | 'login' | 'register'): void {
    this.$router.push(this.$router.resolve({
      name: destination,
    }).route.fullPath);
  }
}
